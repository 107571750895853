<template>
  <div>
    <x-label
      v-if="label"
      tag="label"
      for="passengers"
      style="text-transform: uppercase"
    >
      {{ label }}
    </x-label>
    <form-input
      id="passengers"
      style="color: var(--color-primary)"
      type="text"
      autocomplete="off"
      readonly
      :value="displayValue"
      @focus="showPaxModal"
    />
    <transition name="fade" appear>
      <div v-show="showModal" class="pax-modal-bg"></div>
    </transition>
    <transition name="float-l" appear>
      <div
        v-show="showModal"
        v-click-outside="hidePaxModal"
        class="pax-modal container-xs app-card"
      >
        <p class="fs-13" style="font-family: LuciferSans">
          {{ $t('search.add-passengers') }}
        </p>
        <div v-for="pax in types" :key="pax" class="pax-section">
          <p>{{ model[pax] }}</p>
          <div style="display: grid">
            <p>{{ text[pax].toUpperCase() }}</p>
            <p>{{ $t(`search.passenger-age.${pax}`) }}</p>
          </div>
          <div
            style="
              display: grid;
              gap: 1rem;
              grid-template-columns: repeat(3, auto);
              justify-self: flex-end;
            "
          >
            <plus-minus-button
              id="adult-remove"
              :disable="limit.subtract[pax]"
              minus
              @click="model[pax] -= 1"
            />
            <div class="pax-border"></div>
            <plus-minus-button
              id="adult-add"
              :disable="limit.add[pax]"
              @click="model[pax] += 1"
            />
          </div>
        </div>
        <x-button style="margin-top: 1rem" @click="hidePaxModal">
          Continue
        </x-button>
      </div>
    </transition>
  </div>
</template>

<script>
import PlusMinusButton from '../../../components/common/PlusMinusButton.vue'
import { PASSENGER } from '../../../constants/PASSENGER'

export default {
  name: 'PassengersInput',
  components: { PlusMinusButton },
  props: { value: Object, label: String },
  data() {
    return {
      types: [PASSENGER.TYPE.ADULTS],
      model: {
        adults: this.value.adults,
        children: this.value.children,
        infants: this.value.infants,
      },
      maxPassengers: 6,
      showModal: false,
    }
  },
  watch: {
    model: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      },
    },
  },
  computed: {
    limit() {
      const max = this.maxPassengers
      const { children, infants, adults } = this.model

      const limit = {
        subtract: {
          adults: adults <= 1,
          children: children <= 0,
          infants: infants <= 0,
        },
        add: {
          adults: adults >= max - (children + infants),
          children: children >= max - (adults + infants),
          infants: infants >= max - (adults + children),
        },
      }
      return limit
    },
    text() {
      const { adults, children, infants } = this.model
      return {
        adults: adults === 1 ? this.$t('cards.adult') : this.$t('cards.adults'),
        children:
          children === 1 ? this.$t('cards.child') : this.$t('cards.children'),
        infants:
          infants === 1 ? this.$t('cards.infant') : this.$t('cards.infants'),
      }
    },
    displayValue() {
      const { adults, children, infants } = this.model
      const count = adults + children + infants
      return `${count} ${count === 1 ? 'Traveler' : 'Travelers'}`
    },
  },
  methods: {
    showPaxModal() {
      this.showModal = true
      this.paxModalOpenedAt = Date.now()
    },
    hidePaxModal() {
      const delay = 200
      if (Date.now() > this.paxModalOpenedAt + delay) this.showModal = false
    },
  },
}
</script>

<style scoped lang="scss">
.pax-section {
  display: grid;
  grid-template-columns: 30px auto 1fr;
  gap: 1rem;
  & > p:first-child {
    color: var(--color-primary);
    font-family: DDin;
    font-weight: bold;
    font-size: 2.25rem;
    margin-right: 0.5rem;
  }
  & > div {
    align-items: center;
    & > p:first-child {
      font-size: 1.125rem;
      font-family: LuciferSans;
      color: #000;
      letter-spacing: 0.06em;
    }
    & > p:last-child {
      font-size: 0.75rem;
      color: var(--color-grey);
      font-family: SpaceMono;
      letter-spacing: 0.2px;
    }
  }
}
.pax-border {
  width: 1px;
  height: 80%;
  background: #c4c4c4;
}
.pax-modal {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0px;
  @include s {
    bottom: 10px;
  }
  background-color: white;
  width: 100%;
  border-radius: 2rem;
  padding-top: 16px;
  padding-bottom: 16px;
  display: grid;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  width: 380px;
  max-width: 95vw;
  user-select: none;
}
.pax-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0008;
}
</style>
