<template>
  <div class="search-wrap container-xs">
    <AppHeader back-to="/search" :title="props.TITLE" />
    <form
      class="search-form"
      :data-type="searchType"
      autocomplete="off"
      @submit.prevent
    >
      <!-- <TierInput
        v-if="searchParametersMap.tier.searchTypes.includes(searchType)"
        v-model="tier"
        :label="props.LABELS.TIER"
        style="grid-area: budget"
      /> -->
      <BudgetInput
        v-if="searchParametersMap.budget.searchTypes.includes(searchType)"
        v-model="budget"
        :label="props.LABELS.BUDGET"
        style="grid-area: budget"
      />
      <!-- DEPARTURE LOCATION -->
      <LocationInput
        v-if="searchParametersMap.departure.searchTypes.includes(searchType)"
        v-model="departure"
        style="grid-area: city"
        :label="props.LABELS.DEPARTURE"
        :placeholder="props.LABELS.DEPARTURE_MODAL"
        :location-type="searchParametersMap.departure.locationType[searchType]"
        use-nearest-location
        :saved-location="departureLocation"
        @select="location => (departureLocation = location)"
      />
      <!-- DESTINATION LOCATION -->
      <LocationInput
        v-if="searchParametersMap.destination.searchTypes.includes(searchType)"
        v-model="destination"
        style="grid-area: destination"
        :label="props.LABELS.DESTINATION"
        :placeholder="props.LABELS.DESTINATION_MODAL"
        :location-type="
          searchParametersMap.destination.locationType[searchType]
        "
        :saved-location="destinationLocation"
        @select="location => (destinationLocation = location)"
      />
      <!-- DEPARTURE DATE -->
      <DateInput
        v-if="
          searchParametersMap.departureDate.searchTypes.includes(searchType)
        "
        v-model="departureDate"
        :parameter="searchParametersMap.departureDate"
        style="grid-area: departure"
        :label="props.LABELS.DEPARTURE_DATE"
      />
      <!-- RETURN DATE -->
      <DateInput
        v-if="searchParametersMap.returnDate.searchTypes.includes(searchType)"
        :key="departureDate"
        v-model="returnDate"
        :parameter="searchParametersMap.returnDate"
        style="grid-area: return"
        :label="props.LABELS.RETURN_DATE"
      />
      <PassengersInput
        v-model="passengers"
        style="grid-area: passengers"
        :label="props.LABELS.PASSENGERS"
      />
      <PreferenceInput
        v-if="searchParametersMap.preferences.searchTypes.includes(searchType)"
        v-model="preferences"
        style="grid-area: preferences"
        :label="props.LABELS.PREFERENCES"
        :inner-label="props.LABELS.PREFERENCES_INNER"
      />
    </form>
    <!-- <pre>{{ getParams.search }}</pre> -->
    <XButton
      id="submit"
      type="main"
      style="min-width: 300px; align-self: flex-end"
      :disabled="!searchFormValid"
      @click="startSearch"
    >
      {{ $t('search.explore') }}
    </XButton>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import LocationInput from '../../components-rf/forms/LocationInput.vue'
import BudgetInput from '../../components-rf/search/forms/BudgetInput.vue'
import DateInput from '../../components-rf/search/forms/DateInput.vue'
import PassengersInput from '../../components-rf/search/forms/PassengersInput.vue'
// import TierInput from '../../components-rf/search/forms/TierInput.vue'
import AppHeader from '../../components-rf/AppHeader.vue'
import XButton from '../../components/common/global/XButton.vue'
import { LOCATION } from '../../constants/LOCATION'
import { SEARCH } from '../../constants/SEARCH'
import { appAnalytics } from '../../services/analytics/analytics'
import { searchParametersMap } from '../../store/modules/SearchForm'
import PreferenceInput from '../../components-rf/forms/PreferenceInput.vue'

const link = key => ({
  get() {
    return this.getParams.all[key]
  },
  set(value) {
    this.setParams({ [key]: value })
  },
})

export default {
  name: 'SearchForm',
  components: {
    XButton,
    // TierInput,
    BudgetInput,
    LocationInput,
    DateInput,
    PassengersInput,
    AppHeader,
    PreferenceInput,
  },
  data() {
    return {
      SEARCH,
      searchParametersMap,
      minDepartureDate: '',
      minReturnDate: '',
    }
  },
  created() {
    this.init()
  },
  mounted() {
    const trackId = {
      [SEARCH.TYPE.PACKAGE]: 'search_screen_package_view',
      [SEARCH.TYPE.COMBO]: 'search_screen_flight_and_hotel_view',
      [SEARCH.TYPE.FLIGHT]: 'search_screen_flight_view',
      [SEARCH.TYPE.HOTEL]: 'search_screen_hotel_view',
    }[this.searchType]
    appAnalytics.page({ title: trackId })
  },
  computed: {
    ...mapState('SearchForm', ['form']),
    ...mapGetters('UserModule', ['userLocation']),
    ...mapGetters('SearchForm', ['getParams', 'searchFormValid', 'get']),
    ...mapGetters('LocationModule', ['locations']),
    searchType: vm => vm.form.searchType,
    tier: link('tier'),
    budget: link('budget'),
    departure: link('departure'),
    departureLocation: link('departureLocation'),
    destination: link('destination'),
    destinationLocation: link('destinationLocation'),
    departureDate: link('departureDate'),
    returnDate: link('returnDate'),
    preferences: link('preferences'),
    passengers: {
      set(passengers) {
        this.setParams(passengers)
      },
      get() {
        const { adults, children, infants } = this.form
        return { adults, children, infants }
      },
    },
    props() {
      return {
        LABELS: {
          [SEARCH.TYPE.PACKAGE]: {
            BUDGET: 'YOUR MAXIMUM BUDGET',
            DEPARTURE: 'LEAVING FROM?',
            DEPARTURE_MODAL: 'LEAVING FROM',
            DEPARTURE_DATE: 'DEPARTURE',
            RETURN_DATE: 'RETURN',
            PASSENGERS: 'TRAVELERS',
            PREFERENCES: 'NARROW BY INTEREST',
            PREFERENCES_INNER: 'Explore by your interest',
          },
          [SEARCH.TYPE.COMBO]: {
            TIER: 'TIERS',
            DEPARTURE: 'WHERE FROM?',
            DEPARTURE_MODAL: 'LEAVING FROM',
            DESTINATION_MODAL: 'WHERE TO',
            DESTINATION: 'WHERE TO?',
            DEPARTURE_DATE: 'DEPARTURE',
            RETURN_DATE: 'RETURN',
            PASSENGERS: 'TRAVELERS',
          },
          [SEARCH.TYPE.FLIGHT]: {
            DEPARTURE: 'LEAVING FROM',
            DEPARTURE_MODAL: 'LEAVING FROM',
            DESTINATION: 'WHERE TO?',
            DESTINATION_MODAL: 'WHERE TO',
            DEPARTURE_DATE: 'DEPARTURE',
            RETURN_DATE: 'RETURN',
            PASSENGERS: 'TRAVELERS',
          },
          [SEARCH.TYPE.HOTEL]: {
            DESTINATION: 'WHERE TO?',
            DESTINATION_MODAL: 'WHERE TO',
            DEPARTURE_DATE: 'CHECK-IN',
            RETURN_DATE: 'CHECK-OUT',
            PASSENGERS: 'TRAVELERS',
          },
        }[this.searchType],
        TITLE: {
          [SEARCH.TYPE.PACKAGE]: `Relax, we've got you covered.`,
          [SEARCH.TYPE.COMBO]: 'All the details, with ease.',
          [SEARCH.TYPE.FLIGHT]: 'Fly with us, wherever.',
          [SEARCH.TYPE.HOTEL]: 'Comfort hotels at your fingertips.',
        }[this.searchType],
      }
    },
  },
  methods: {
    ...mapMutations('SearchForm', ['setParams']),
    ...mapActions('LocationModule', ['getLocations']),
    async init() {
      const isFlightDepartureLocation =
        searchParametersMap.departure.locationType[this.searchType] ===
        LOCATION.TYPE.FLIGHT
      const isMissingDeparture = !this.departure

      // set user preferred departure location
      if (isFlightDepartureLocation && isMissingDeparture) {
        if (!this.locations.length) await this.getLocations()
        if (this.userLocation) this.departure = this.userLocation.id
      }
    },
    startSearch() {
      this.$router.push({ path: '/search/q', query: this.getParams.search })
    },
  },
}
</script>

<style scoped lang="scss">
.search-wrap {
  height: 100%;
  @include s {
    background: white;
    border-radius: 2rem;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  box-sizing: border-box;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 2rem;
}
.search-form {
  align-self: flex-start;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;

  &[data-type='package'] {
    grid-template-rows: repeat(5, auto);
    grid-template-areas: 'budget budget' 'city city' 'departure return' 'passengers passengers' 'preferences preferences';
  }
  &[data-type='combo'] {
    grid-template-rows: repeat(5, auto);
    grid-template-areas: 'budget budget' 'city city' 'destination destination' 'departure return' 'passengers passengers';
  }
  &[data-type='flight'] {
    grid-template-rows: repeat(5, auto);
    grid-template-areas: 'city city' 'destination destination' 'departure return' 'passengers passengers';
  }
  &[data-type='hotel'] {
    grid-template-rows: repeat(3, auto);
    grid-template-areas: 'city city' 'destination destination' 'departure return' 'passengers passengers';
  }
}

.no-results {
  line-height: 1.2;
  text-align: center;
  &.title {
    font-size: 36px;
    font-family: GTSuper;
    margin-top: 3rem;
  }
  &.text {
    font-size: 17px;
    margin-top: 1rem;
  }
}
</style>
