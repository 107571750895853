<template>
  <div v-show="isShown" class="preference-list" @click="onWrapperClick">
    <div
      class="container-xs snt-grid large"
      style="grid-template-rows: auto 1fr auto; margin-top: 16px"
    >
      <p class="preference-list-title">{{ innerLabel }}</p>
      <div v-if="preferences.length" class="snt-flex-wrap">
        <div v-for="{ name, id } in preferences">
          <button
            :data-active="value.includes(id)"
            class="preference-list-item"
            @click="togglePreference(id)"
          >
            {{ name }}
          </button>
        </div>
      </div>
      <AppLoader
        v-else
        style="margin-left: auto; margin-right: auto"
        size="40"
      />
      <XButton type="main" :is-loading="isLoading" @click="submit"
        >DONE</XButton
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import XButton from '../../components/common/global/XButton.vue'
import AppLoader from '../AppLoader.vue'

export default {
  name: 'LocationInputModal',
  components: { XButton, AppLoader },
  props: {
    value: Array,
    innerLabel: String,
    preferences: Array,
  },
  data() {
    return {
      isLoading: false,
      isShown: false,
      hideDelay: 200,
      openedAt: Date.now(),
    }
  },
  methods: {
    togglePreference(id) {
      let newValue = undefined
      if (this.value.includes(id)) newValue = this.value.filter(el => el !== id)
      else newValue = [...this.value, id]
      this.$emit('input', newValue)
    },
    show() {
      this.isShown = true
      this.openedAt = Date.now()
    },
    hide() {
      if (Date.now() > this.openedAt + this.hideDelay) this.isShown = false
    },
    onWrapperClick(e) {
      if (e.target.getAttribute('data-hide-on-click') === '') this.hide()
    },
    submit() {
      if (this.isLoggedIn) {
        this.isLoading = true
        this.$store
          .dispatch('UserModule/updateUserPreferences', this.value)
          .then(this.hide)
          .finally(() => (this.isLoading = false))
      } else this.hide()
    },
  },
  computed: {
    ...mapState('AuthModule', ['isLoggedIn']),
  },
}
</script>

<style scoped lang="scss">
.preference-list {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-top: 0.5rem;
  background: var(--color-light);
}
.preference-list-title {
  font-family: SFPro;
  font-size: 22px;
  font-weight: 600;
}
.preference-list-item {
  font-family: DDin;
  font-size: 16px;
  color: #444;
  border: 1px solid #444;
  border-radius: 10px;
  padding: 9px 10px;
  font-weight: 700;
  &[data-active] {
    color: white;
    background: #444;
  }
}
</style>
