<template>
  <div>
    <div>
      <x-label v-if="label" tag="label" for="date-input">
        {{ label }}
      </x-label>
      <form-input
        id="date-input"
        :value="displayDate"
        style="color: var(--color-primary)"
        required
        :validator="validator"
        @focus="e => $refs.date_modal.open(e)"
      />
    </div>
    <datetime
      ref="date_modal"
      :key="forceUpdate"
      :value="value"
      :min-datetime="constraints.min"
      :max-datetime="constraints.max"
      auto
      :value-zone="$timezone"
      @input="handleDateInput"
    />
  </div>
</template>

<script>
import { date } from '../../../other/utils/helpers/date'
import { mapState } from 'vuex'

export default {
  name: 'DateInput',
  props: {
    value: String,
    label: String,
    parameter: Object,
  },
  data() {
    const value = this.value
    return { forceUpdate: value }
  },
  computed: {
    ...mapState('SearchForm', ['form']),
    displayDate() {
      if (!this.value) return
      return date.formatUTC('-d2 -ms, -ds', this.value)
    },
    constraints() {
      return {
        min: this.parameter.min(this.form).date,
        max: this.parameter.max(this.form).date,
      }
    },
  },
  methods: {
    handleDateInput(value) {
      const parsedValue = value.split('T')[0]
      this.forceUpdate = parsedValue
      this.$emit('input', parsedValue)
    },
    validator() {
      return this.parameter.validator(this.form, this.value)
    },
  },
}
</script>

<style scoped lang="scss"></style>
