<template>
  <div
    class="x-wrap"
    :data-clickable="!disable"
    @click="!disable && $emit('click')"
  >
    <icon
      :icon="minus ? 'ShapeMinus' : 'ShapePlus'"
      :color="disable ? 'grey' : ''"
    />
  </div>
</template>

<script>
export default {
  name: 'PlusMinusButton',
  props: {
    minus: Boolean,
    disable: Boolean,
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.x-wrap {
  height: 43px;
  width: 43px;
  border: 1.5px solid #000000;
  box-sizing: border-box;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.1s ease;
  background: #0000;
  pointer-events: none;
  &[data-clickable='true'] {
    cursor: pointer;
    pointer-events: initial;
  }
  &:hover[data-clickable='true'] {
    background: #00000008;
  }
  &:active[data-clickable='true'] {
    background: #0001;
  }
}
</style>
