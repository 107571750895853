<template>
  <div>
    <x-label v-if="label" tag="label" for="budget-manual-input">
      {{ label }}
    </x-label>
    <label class="budget-input">
      <p>$</p>
      <input
        id="budget-manual-input"
        :value="textModel"
        name="budget"
        inputmode="numeric"
        type="text"
        class="budget-input-element"
        @focus="forceReload += 1"
        @input="onNumberInput"
        @blur="updateTextModel"
      />
    </label>
    <form-input
      :key="forceReload"
      v-model="sliderModel"
      type="range"
      step="50"
      name="budget"
      :min="min"
      :max="max"
    />
  </div>
</template>

<script>
import { debounce } from '../../../other/utils/helpers/throttle'
import Cleave from 'cleave.js'

export default {
  name: 'BudgetSlider',
  props: {
    value: { type: Number, default: 2000 },
    label: String,
  },
  mounted() {
    this.cleave = new Cleave('#budget-manual-input', {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
    })
    this.updateTextModel()
  },
  data() {
    return {
      forceReload: 0,
      textModel: '',
      sliderModel: this.value,
      model: this.value,
      min: 100,
      max: 15000,
      cleave: undefined,
    }
  },
  methods: {
    onNumberInput(e) {
      const str = e.target.value
      const num = Number(str.replace(/[^0-9]/g, ''))
      this.model = this.sliderModel =
        num > this.max ? this.max : num < this.min ? this.min : num
      this.textModel = str
    },
    updateTextModel() {
      this.cleave.setRawValue(this.model + '')
      this.textModel = this.cleave.getFormattedValue()
    },
  },
  watch: {
    model: debounce(function (model) {
      this.$emit('input', +model)
    }, 200),
    sliderModel(value) {
      const isSliderChange = value !== this.model
      this.model = value
      if (isSliderChange) this.updateTextModel()
    },
  },
}
</script>

<style scoped lang="scss">
.budget-input {
  display: flex;
  font-size: 3rem;
  color: var(--color-primary);
  font-family: LuciferSans;
}
.budget-input-element {
  -moz-appearance: textfield;
  width: 100%;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
