<template>
  <div>
    <XLabel v-if="label" tag="label" for="preferences">{{ label }}</XLabel>
    <FormInput
      id="preferences"
      ref="input"
      name="preferences"
      style="font-family: LuciferSans; color: var(--color-primary)"
      required
      :value="displayValue"
      readonly
      @focus="$refs.preferenceModal.show()"
      @click="$refs.preferenceModal.show()"
    />
    <PreferenceInputList
      ref="preferenceModal"
      :value="value"
      :inner-label="innerLabel"
      :preferences="preferences"
      @input="val => $emit('input', val)"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import XLabel from '../../components/common/global/XLabel.vue'
import FormInput from '../../components/utils/FormInput.vue'
import PreferenceInputList from './PreferenceInputList.vue'

export default {
  name: 'PreferencesInput',
  components: { FormInput, PreferenceInputList, XLabel },
  props: {
    value: Array,
    label: String,
    innerLabel: String,
  },
  computed: {
    ...mapState('PreferenceModule', ['preferences']),
    ...mapGetters('PreferenceModule', ['displayPreferences']),
    displayValue() {
      return this.displayPreferences(this.value)
    },
  },
}
</script>

<style scoped lang="scss"></style>
